.reset_password_form {
  display: flex;
  flex-direction: column;
  font-family: Mitr;
  background-color: white;
}

.warn_text {
  color: red;
  font-size: 0.8em;
  align-self: flex-start;
  font-family: Prompt;
}

@media only screen and (min-width: 768px) {
  .reset_password_form {
    border-radius: 20px;
    border: 2px solid #68b6b4;
    box-shadow: 0 3px 15px #555;
    padding: 5vh 5vh 5vh 5vh;
  }
}
