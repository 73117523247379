.construction_container {
  width: 60%;
  margin: auto;
  text-align: center;
  margin-top: 20vh;
}

.construction_container>img {
  width: 20%;
}

.construction_container>h1 {
  font-size: 4em;
}

.construction_container>h4 {
  font-size: 1.5em;
}