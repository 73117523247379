p {
  color: grey;
}

.login_form {
  text-align: center;
  margin: auto;
}

.forgot_password {
  text-align: right;
}

.remember_me {
  text-align: left;
  display: flex;
  padding-left: 10px;
}

.remember_me--checkbox {
  transform: scale(2);
}

.remember_me--text {
  margin-left: 2vh;
}

.create_account_link {
  margin-top: 20px;
}

.create_account_link > a {
  text-decoration: underline;
  color: grey;
}

.text_header {
  text-align: left;
  margin-bottom: 20px;
  font-family: BreakFill;
  color: #f13d57;
}

.text_warning {
  color: red;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: left;
}

.close_form {
  color: #f13d57;
  text-align: right;
  display: none;
}

@media only screen and (min-width: 768px) {
  .close_form {
    display: block;
  }
}
