.forgot_password_page {
  flex-grow: 1;
}

@media only screen and (min-width: 768px) {
  .forgot_password_page {
    background-image: url("./../assets/images/map_full.png");
    background-size: cover;
    flex-grow: 1;
    padding: 5% 30%;
  }
}
