.category_feed_page_container {
  /* background: url("./../assets/images/map_trans.PNG"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff5e8;
  font-family: Prompt;
  flex-grow: 1;
}

.category_feed {
  padding: 20px;
}

.text_center {
  text-align: center;
}

.category_selection_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}

.category_selection_container > h3 {
  font-weight: bold;
  font-size: 1.3em;
  color: #f13d57;
}

.category_selection_container_input {
  width: 90%;
}

@media only screen and (min-width: 768px) {
  .category_feed_page_container {
    padding: 0 30vw;
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-color: #fff5e8;
  }

  .category_selection_container_input {
    width: 90%;
  }

  .category_selection_container {
    margin-top: 5vh;
  }
}
