.new_story_page_container {
  padding: 30px 20px;
  /* height: 100%; */
  background: linear-gradient(#eee, #FFEFD8);
  flex-grow: 1;
  font-family: Prompt;
}

@media only screen and (min-width: 768px) {
  .new_story_page_container {
    padding: 8vh 20vw;
  }
}