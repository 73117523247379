.sharepage_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sharebox_container {
  width: 100vw;
  height: 100vw;
  background-image: url("./../assets/images/map_full.png");
  background-size: cover;
  position: relative;
}

.nb_pattern_back {
  width: 70%;
  height: 80%;
  transform: rotate(-7deg);
  background: url("./../assets/images/notebook/pattern/Pattern1.png");
  position: absolute;
  margin-top: 40%;
  margin-left: 13%;
  border-left: 2px grey solid;
}

.nb_pattern_front {
  z-index: 2;
  width: 70%;
  height: 80%;
  transform: rotate(-5deg);
  background: url("./../assets/images/notebook/pattern/Pattern1.png");
  margin-top: 30%;
  margin-left: 18%;
  position: absolute;
  border-left: 2px grey solid;
}

.logo {
  width: 20%;
  position: absolute;
  right: 10px;
}

.content_container {
  padding: 10px;
}

.content_container>p {
  margin-bottom: 0;
  font-family: Prompt;
  color: #F13D57;
}

.your_sticker_text {
  text-align: center;
  margin-top: 10px;
}

.stickers_container {}

.stickers_container>p {
  margin-top: 20px;
  font-family: Prompt;
}

.stickers_container img {
  width: 25%;
  margin: 2.5%;
}

.prompt_container {
  width: 100%;
  height: 60vh;
  flex-grow: 1;
  z-index: 10;
  background: white;
  padding: 20px;
  font-family: Prompt;
}


@media only screen and (min-width: 768px) {
  .sharebox_container {
    width: 50vw;
    height: 50vw;
  }
}