.landing_page {
  background-image: url("./../assets/images/landing_page_bg.png");
  flex-grow: 1;
  background-size: cover;
  padding: 20px;
  font-family: BaiJamjuree;
  font-weight: lighter;
  font-size: 0.9em;
}

.text_bold_weight {
  font-family: BaiJamjuree;
  font-weight: bold;
}

.text_bold_italic {
  font-family: BaiJamjuree;
  font-weight: bold;
  font-style: italic;
}

.text_normal_weight {
  font-family: BaiJamjuree;
  font-weight: normal;
}

.logo_container {
  text-align: center;
}

.logo {
  margin-top: 1em;
  width: 35vw;
}

@media only screen and (min-width: 768px) {
  .logo {
    width: 12vw;
  }

  .content_container {
    overflow: hidden;
    width: 50vw;
    font-size: 1em;
  }

  .landing_page {
    background-image: url("./../assets/images/desktop_no_logo.png");
    background-size: cover;
  }
}

@media only screen and (max-width: 767px) {
  .content_container {
    overflow: scroll;
  }
}

.content_container {
  text-align: left;
  margin-top: 2vh;
  border-radius: 10px;
  padding: 20px;
  background-color: rgb(241, 61, 87, 0.9);
  max-height: 65vh;
  margin: auto;
}

.content_container > p {
  color: white;
}

.tap_container {
  width: 100%;
  margin: auto;
  position: fixed;
  text-align: center;
  bottom: 2vh;
  left: 0;
}

.tap_container > h4 {
  display: inline;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0px 2px #ff0000;
  }

  to {
    text-shadow: 0px 2px #fff;
  }
}

.mynode_enter {
  opacity: 0;
}

.mynode_enter_active {
  opacity: 1;
  transition: opacity 1000ms;
}

.mynode_exit {
  opacity: 1;
}

.mynode_exit_active {
  opacity: 0;
  transition: opacity 1000ms;
}
