@media only screen and (min-width: 768px) {
  .mobile_nav {
    display: none;
  }

  .desktop_nav {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .desktop_nav {
    display: none;
  }
}

.desktop_nav {
  flex-grow: 1;
  max-width: 20vw;
}

.desktop_layout_controller {
  display: flex;
  flex-grow: 1;
}

.content_container {
  flex-grow: 1;
}