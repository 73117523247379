.privacy_policy_page {
  padding: 3vh 5vw;
  font-family: Mitr;
  text-align: left;
}

.privacy_policy_page > h1 {
  font-size: 2em;
  color: #f13d57;
}

.privacy_policy_page > h2 {
  font-size: 1.5em;
}

.privacy_policy_page > p {
  color: #000;
}

.lang_toggle_container {
  margin-top: 3vh;
  display: flex;
  justify-content: flex-end;
  padding: 0 5vw;
  color: #f13d57;
}

.lang_toggle_container > a {
  color: #f13d57;
}

@media only screen and (min-width: 768px) {
  .privacy_policy_page {
    width: 70%;
    margin: auto;
  }
}
