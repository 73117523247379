@font-face {
  font-family: "BaiJamjuree";
  src: url("./assets/fonts/Bai_Jamjuree/BaiJamjuree-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BreakFill";
  src: url("./assets/fonts/BreakFill/break_regular_fill.ttf") format("truetype");
}

@font-face {
  font-family: "CrimsonPro";
  src: url("./assets/fonts/Crimson_Pro/static/CrimsonPro-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CrimsonPro";
  src: url("./assets/fonts/Crimson_Pro/static/CrimsonPro-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "CrimsonPro";
  src: url("./assets/fonts/Crimson_Pro/static/CrimsonPro-Bold.ttf") format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Mitr";
  src: url("./assets/fonts/Mitr/Mitr-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Prompt";
  src: url("./assets/fonts/Prompt/Prompt-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Prompt";
  src: url("./assets/fonts/Prompt/Prompt-SemiBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Prompt";
  src: url("./assets/fonts/Prompt/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Prompt";
  src: url("./assets/fonts/Prompt/Prompt-ExtraLight.ttf") format("truetype");
  font-weight: lighter;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fcf9f5;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}