.report_page {
  background-image: url("./../assets/images/map_full.png");
}

@media only screen and (min-width: 768px) {
  .report_page {
    padding: 10vh 35vw;
    flex-grow: 1;
  }
}
