.sticker_sharebox_container {
  background-color: #D3CECE;
  border-radius: 10px;
}

.sticker_pane {
  width: 100%;
  padding: 20px;
}

.sticker_row {
  display: flex;
}

.sticker_row>img {
  max-width: 33%;
  height: auto;
}