.signup_complete_container {
  display: flex;
  margin: auto;
  flex-flow: column;
  text-align: center;
}

.confetti {
  width: 20vw;
}

.checked {
  width: 50vw;
}