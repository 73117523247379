.footer {
  background-color: #f13d57;
  width: 100%;
  position: relative;
  bottom: 0;
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  font-family: Mitr;
  font-weight: normal;
  text-align: left;
}

.row {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
  flex-direction: row;
}

.content_container {
  justify-content: space-between;
  margin-bottom: 3vh;
}

.copyrighted {
  text-align: center;
}

.copyrighted > p {
  color: #fff;
  font-size: 0.7em;
  margin: 0;
}

.logo_container > h5 {
  color: #fff;
  text-align: left;
  font-size: 0.6em;
}

.logo_container > img {
  width: 100px;
}

.about_container {
  text-align: end;
}

.about_container > h5 {
  color: #fff;
  font-size: 0.6em;
  text-align: right;
}

.about_container p {
  color: #fff;
  margin: 0;
  font-size: 0.5em;
}

.fake_footer {
  height: 3vh;
}

.social_icon_container img {
  width: 40px;
  margin: 0 2px;
}

.made_by_container {
  display: flex;
  flex-direction: column;
}

.made_by_container > h5 {
  text-align: left;
  color: #fff;
  font-size: 0.7em;
}

.made_by_container img {
  width: 80px;
  height: 40px;
}

.drg_container {
  display: flex;
  flex-direction: row;
}

.drg_container > p {
  margin-left: 0.3em;
  color: #fff;
  font-size: 0.5em;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0.5em 2em;
  }

  .logo_container > h5 {
    font-size: 1em;
  }

  .social_icon_container img {
    width: 50px;
    margin: 0 2px;
  }

  .made_by_container > h5 {
    font-size: 1em;
  }

  .drg_container > img {
    width: 120px;
    height: 60px;
  }

  .drg_container > p {
    margin-left: 1em;
    font-size: 0.8em;
  }

  .about_container > h5 {
    font-size: 1em;
  }

  .about_container p {
    font-size: 0.8em;
  }
}
