.story_detail_page {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  background-image: url("./../assets/images/map_trans.PNG");
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-color: #fde5de;
  min-height: 100vh;
}

.story_detail_modal {
  z-index: 1;
  width: "100%";
  height: "100%";
  top: 0;
  left: 0;
  position: "fixed";
  overflow-y: "scroll";
}

.logo_container {
  width: 100px;
}

.logo_container > img {
  filter: drop-shadow(3px 3px 2px #333);
}

.location_icon {
  width: 40%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -5%;
}

.story_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.story_banner {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fe8f7c;
  margin-top: 20%;
  padding: 30px 20px 30px 20px;
  flex-grow: 1;
}

.story_banner > h1 {
  margin-top: 10px;
  font-size: 1.5em;
  color: #ffefd8;
  font-family: Mitr;
  text-align: left;
}

.story_banner_icon {
  text-align: right;
  width: 100%;
}

.content_container {
  padding: 5%;
  background-color: #fdfaf6;
  display: flex;
  flex-grow: 20;
  flex-direction: column;
  text-align: left;
  white-space: pre-wrap;
}

.content_container > h4 {
  color: #f13d57;
  font-family: Mitr;
  font-size: 1.2em;
}

.content_container p {
  margin-top: 20px;
  text-align: justify;
  color: #f13d57;
  font-family: BaiJamjuree;
}

.content_container > button {
  align-self: center;
}

.content_container_sticker_bar {
  color: #68b7b4;
  display: flex;
  align-items: center;
}

.categories_container {
  margin-bottom: 20px;
}

.categories_container > p {
  margin: 0;
}

.category_line {
  width: 30%;
  height: 1px;
  background: #f13d57;
  margin: 10px 0;
}

.sticker_counter {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.sticker_counter > img {
  width: 40px;
}

.textbold {
  font-weight: bolder;
}

.story_container_enter {
  transform: translateY(100%);
}

.story_container_enter_active {
  transform: translateY(0);
  transition: 1s ease-in-out;
}

.story_container_enter_active {
  transform: translateY(0);
}

.story_container_exit {
  transform: translateY(100%);
  transition: 0.5s;
}

.small_header_enter {
  transform: translateY(-100%);
}

.small_header_enter_active {
  transform: translateY(0%);
  transition: 1s;
}

.small_header_exit_active {
  transform: translateY(0%);
}

.small_header_exit {
  transform: translateY(-100%);
  transition: 1s;
}

.sticker_sharebox_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sticker_sharebox_button a {
  color: #f13d57;
  text-decoration: underline;
}

.sticker_sharebox {
  position: absolute;
  width: 90%;
  margin-bottom: 10px;
  transform: translateY(-110%);
}

.sticker_sharebox_button {
  text-align: center;
  width: 100%;
}

.sticker_confirm_modal {
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: rgb(40, 40, 40, 0.7);
  display: flex;
  align-items: center;
}

.small_text {
  font-size: 0.8em;
}

.text_center {
  text-align: center !important;
}

.report_container {
  width: 100%;
  margin-top: 3vh;
}

.report_container > p {
  text-align: right;
  font-size: 0.8em;
}

@media only screen and (min-width: 768px) {
  .story_detail_page {
    min-height: 100%;
    padding: 0 20%;
  }

  .location_icon {
    width: 15%;
    margin-top: 0%;
  }

  .story_banner {
    padding: 20px 5% 10px 5%;
  }

  .sticker_sharebox {
    width: 30%;
  }

  .sticker_sharebox_button {
    width: 50%;
  }

  .sticker_confirm_modal {
    padding: 0% 36%;
  }
}
