.page_not_found {
  background-image: url("./../assets/images/map_full.png");
  background-size: cover;
  flex-grow: 1;
}

.page_not_found_container {
  background: #fcf9f5;
  box-shadow: 0px 5px 10px 0px #00000040;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: CrimsonPro;
  font-weight: bold;
  color: #f13d57;
}

.fof_container {
  width: 80%;
  text-align: center;
  margin-bottom: 3vh;
}

.fof_container>h1 {
  font-size: 5em;
  margin-bottom: 0;
}

.fof_container>h2 {
  font-size: 1.4em;
}

.break_line {
  width: 80%;
  height: 1px;
  background-color: #f13d57;
}

.desc_container {
  margin-top: 4vh;
  text-align: center;
  margin-bottom: 3vh;
  font-family: Prompt;
  font-weight: bold;
}

.desc_container>img {
  width: 80%;
  margin-bottom: 3vh;
}

@media only screen and (min-width: 768px) {
  .page_not_found {
    padding-top: 10vh;
  }

  .page_not_found_container {
    width: 60%;
    margin: auto;
  }

  .desc_container>img {
    width: 30%;
    height: auto;
  }
}