.loading_page {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fcf9f5;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 2s;
}

.loading_page>img {
  width: 30vw;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.spinner_container {
  margin-top: 1em;
  display: flex;
  width: 40vw;
  justify-content: space-evenly;
}

.spinner {
  background: #f13d57;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: scaling 2s ease-in-out infinite;
}

@keyframes scaling {
  0% {
    transform: scale(0.2);
  }

  40% {
    transform: scale(1.0);
  }

  60% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(0.2);
  }
}

div:nth-child(0) {
  animation-delay: 0s;
}

div:nth-child(1) {
  animation-delay: 0.2s;
}

div:nth-child(2) {
  animation-delay: 0.4s;
}

div:nth-child(3) {
  animation-delay: 0.6s;
}

div:nth-child(4) {
  animation-delay: 0.8s;
}

div:nth-child(5) {
  animation-delay: 1s;
}

@media only screen and (min-width: 768px) {
  .loading_page>img {
    width: 10vw;
  }
}