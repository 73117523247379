.report_form {
  text-align: left;
  font-family: Prompt;
  padding-top: 7vh;
  background-color: #fff;
  height: 80vh;
}

.report_form > h1 {
  font-size: 1.3em;
  margin: 0;
  padding-left: 20px;
  font-weight: bold;
  line-height: 1.5em;
}

.report_form > h3 {
  font-size: 1.1em;
  padding-left: 20px;
  font-weight: bold;
}

.report_form > p {
  padding: 0 20px;
}

.list_container {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5vh;
}

.list_item {
  margin: 0;
  font-size: 0.9em;
  font-weight: bold;
  padding: 20px 0 20px 5vw;
  border-top: solid 1px #f13d57;
  border-bottom: solid 1px #f13d57;
}

.list_item:hover {
  background-color: blanchedalmond;
  transition: 0.2s;
}

.header {
  padding: 10px 20px;
  background-color: #fe8f7c;
  color: #fcf9f5;
  font-family: Prompt;
  font-weight: bold;
  text-align: left;
}

.selected_type {
  color: #f13d57;
  margin: 30px 0;
}

.error_image {
  width: 120px;
}

.button_container {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 2px 2px 5px #555;
  }

  .report_form {
    height: 60vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 5px #555;
  }

  .list_item {
    padding-left: 40px;
  }

  .report_form > h1 {
    padding-left: 40px;
  }

  .report_form > h3 {
    padding-left: 40px;
  }
}
