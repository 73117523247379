.homepage_container {
  background-color: #ffb5b7;

  /* oveflow-x makes it scrolable */
  overflow-x: scroll;
}

.map_container {
  height: 100%;
  position: relative;
  display: table;
}

.map {
  height: 95vh;
  display: table-cell;
}

.map_navigation_container {
  position: fixed;
  bottom: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon_location_container {
  position: absolute;
  width: 10%;
  font-size: smaller;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 1s;
}

.icon_location_container:hover {
  transform: scale(1.1);
  transform-origin: center;
  transition: .5s;
}

.icon_location_container>p {
  margin-bottom: 0;
  text-align: center;
  color: #68B7B4;
  font-weight: medium;
  font-family: Mitr;

  background-color: #fff;
  padding: 1px 5px;
  border-radius: 3px;
}

.icon_location_container>img {
  width: 80%;
  height: 80%;
  filter: drop-shadow(0px 0px 10px #999);
  margin-bottom: .5em;
}


.icon_bus_container {
  top: 20%;
  left: 45%
}

.icon_school_container {
  top: 30%;
  left: 18%;
}

.icon_uni_container {
  top: 55%;
  left: 23%;
}

.icon_home_container {
  top: 55%;
  left: 14%;
}

.icon_market_container {
  top: 38%;
  left: 27%;
}

.icon_mall_container {
  top: 36%;
  left: 43%;
}

.icon_restaurant_container {
  top: 55%;
  left: 35%;
}

.icon_park_container {
  top: 51%;
  left: 48%;
}

.icon_street_container {
  top: 10%;
  left: 71%;
}

.icon_religious_container {
  top: 35%;
  left: 63%;
}

.icon_religious_container>img {
  width: 60%;
}

.icon_office_container {
  top: 22%;
  left: 73%;
}

.icon_office_container>img {
  width: 60%
}

.icon_gym_container {
  top: 40%;
  left: 82%;
}

.icon_others_container {
  top: 64%;
  left: 66%;
}

.icon_social_container {
  top: 66%;
  left: 50%;
}

.icon_pub_container {
  top: 17%;
  left: 27%;
}

.hidden {
  visibility: hidden;
}

.icon_notebook_container {
  position: fixed;
  top: 60%;
  left: 50%;
}

.icon_notebook_container>img {
  width: 20%;
  position: fixed;
  /* top: 75%; */
  top: 16%;
  left: 73%;
  filter: drop-shadow(2px 2px 2px #555);
}


/* Ipad portrait width is ~850px */
@media only screen and (min-width: 900px) {
  .homepage_container {
    overflow-x: hidden;
    background-color: #FBD0C5;
    height: 100%;
  }

  .icon_notebook_container>img {
    width: 6%;
    left: 90%;
    top: 21%;
  }

  .map {
    width: 100vw;
    height: auto;
  }

}