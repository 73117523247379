.forgot_password_form {
  display: flex;
  flex-direction: column;
  font-family: Mitr;
  align-items: center;
  background-color: white;
  padding: 3%;
  margin-top: 3vh;
}

.forgot_password_form > input {
  /* border-radius: 5px;
  border: 1px solid grey;
  margin-bottom: 10px;
  width: 100%; */
  font-family: Prompt;
  /* padding: 3px 5px 3px 5px; */
}

.forgot_password_form > p {
  font-family: Prompt;
  align-self: flex-start;
}

.warn_text {
  color: red;
  font-size: 0.8em;
  align-self: flex-start;
}

@media only screen and (min-width: 768px) {
  .forgot_password_form {
    border-radius: 20px;
    border: 2px solid #68b6b4;
    box-shadow: 0 3px 15px #555;
    padding: 5vh 5vh 3vh 5vh;
  }
}
