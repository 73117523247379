.user_nav_desktop {
  padding: 10vh 3vw;
  color: #fff;
  background-color: #333;
  height: 100%;
}

.list_item_container {
  list-style-type: none;
  text-align: right;
}

.list_item_container>li {
  margin: 3vh 0;
  font-family: Prompt;
}

.list_item_first {
  font-size: 1.3em;
  word-break: break-all;
  color: #fff;
}

.usernav_item {
  color: white;
}

.usernav_item:hover {
  color: white;
}