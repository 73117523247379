.form_wrapper {
  text-align: center;
  margin: auto;
}

.text_narrowline {
  margin: 0;
}

.text_grey {
  color: grey;
}

.text_header {
  font-family: BreakFill;
  color: #f13d57;
  text-align: left;
}

.checkbox_big {
  transform: scale(2);
}

.term_container {
  margin: auto;
  width: 90%;
  display: flex;
  align-items: center;
}

.term_container--checkbox {
  margin-right: 1em;
}

.term_container--termtext {
  margin: 0;
  font-size: 0.8em;
  text-align: left;
}

.text_underlined {
  text-decoration: underline;
}

.stage_container {
  text-align: left;
}

.input_date {
  width: 100%;
  margin-bottom: 50px;
  border: lightgray solid 1px;
  border-radius: 5px;
  padding: 0 0.5em;
  height: 2.5em;
}

.submit_form_button_container {
  text-align: center;
  margin-bottom: 10vh;
}

.back_icon {
  color: #000;
}

.text_warning {
  color: red;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: left;
}
