.userinfopage_container {
  padding: 30px;
  color: #aeb98a;
  font-family: Prompt;
}

.userinfopage_container > h3 {
  margin-bottom: 40px;
}

.userinfopage_container > p {
  margin: 0;
  color: #aeb98a;
}

.userinfopage_container > input {
  margin-bottom: 20px;
  background: transparent;
  border: solid 1px lightgrey;
  height: 2.3em;
  border-radius: 5px;
  padding: 0 10px;
}

.userinfopage_container > select {
  margin-bottom: 20px;
}

.full_width {
  width: 100%;
}

.text_centered {
  text-align: center;
}

.text_danger {
  color: red;
}

.dangerzone_container {
  margin-top: 10%;
  border: red 1px solid;
  padding: 10px;
  border-radius: 5px;
}

.confirm_delete_container {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .userinfopage_container {
    width: 40%;
    margin: auto;
    color: #aeb98a;
    padding: 10vh 0;
  }
}
