.faq_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.faq_page {
  padding: 40px 20px 20px 20px;
  font-family: Mitr;
}

.title_text {
  font-family: Mitr;
}

.contact_container {
  text-align: center;
}

.contact_container>a {
  color: #F13D57;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .title_text {
    text-align: center;
  }

  .faq_page {
    padding-top: 10vh;
    width: 70%;
    margin: auto;
    text-align: left;
  }

}