.modal_cotainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(0, 0, 0, 0.5);
}

.modal {
  margin: 0 20px;
}

.modal_header {
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #F13D57;
  color: #fff;
  font-weight: bold;
  box-shadow: #666 3px 3px 5px;
}

.modal_content {
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fff;
  box-shadow: #666 3px 3px 5px;
}

.modal_content_button {
  margin-top: 5px;
  text-align: end;
}