.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Prompt;
  flex-grow: 1;
}

.modal_container>p {
  color: #fff;
  font-size: 1.2em;
}

.modal_container>img {
  width: 50%;
  margin-bottom: 2vh;
}

.header_text {
  color: #fff;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1vh;
  font-weight: bold;
}

.button_container {
  width: 80%;
  display: flex;
  justify-content: space-around;
}