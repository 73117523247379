.text_input {
  width: 100%;
  border-style: none none solid none;
  border-width: thin;
  font-size: 1em;
  margin: .5em 0 .5em 0;
  background-color: transparent;
}

.text_input:focus {
  outline: none;
  border-bottom: #76C3C0 solid 0.5px;
}

.text_input::placeholder {
  color: #AEB98A;
}


.text_input_icon {
  width: 20px;
  margin-left: -20px;
}