.faqbox_container {
  width: 100%;
}

.faqbox_question_container {
  position: relative;
  background-color: #dfdede;
  border-radius: 10px;
  padding: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faqbox_question_container > h4 {
  display: inline-block;
  width: 100%;
}

.faqbox_question_container > svg {
  margin-left: -30px;
  font-size: larger;
}

.faqbox_answer_container {
  color: #333;
  position: relative;
  padding: 30px 20px 10px 20px;
  background-color: #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.3s all ease;
  margin-top: -10px;
  transform-origin: top;
  z-index: 0;
  max-height: 500px;
  font-family: Prompt;
}

.faqbox_answer_container_hidden {
  transform-origin: top;
  transform: scaleY(0);
  transition: 0.3s all ease;
  max-height: 0;
  padding: 0px 20px 0 20px;
  visibility: hidden;
}
