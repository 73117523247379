.select_opt {
  color: #F13D57;
  font-weight: bold;
}

.dropdown_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dropdown {
  border: none;
  background-color: transparent;
}

.option_container {
  margin-top: 2em;
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: #68B6B4 solid 2px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px grey;
}

.option_list_item {
  padding: 10px 20px;
  text-align: left;
  color: #68B6B4;
}

.option_list_item_middle {
  padding: 10px 20px;
  text-align: left;
  color: #68B6B4;
  border-top: #68B6B4 solid 2px;
  border-bottom: #68B6B4 solid 2px;
}

.option_list_item:hover {
  /* background-color: #ffeed8; */
  text-decoration: underline;
}

.option_container_enter {
  /* opacity: 0; */
  transform: scaleY(0);
  transform-origin: top;
}

.option_container_enter_active {
  /* opacity: 1; */
  /* transition: opacity 200ms; */
  transform: scaleY(1);
  transition: .3s;
  transform-origin: top;
}

.option_container_exit {
  /* opacity: 1; */
  transform: scaleY(1);
  transition: .3s;
  transform-origin: top;
}

.option_container_exit_active {
  /* opacity: 0; */
  /* transition: opacity 200ms; */
  transform: scaleY(0);
  transform-origin: top;
}