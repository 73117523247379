.form_container {
  box-shadow: 2px 0px 4px grey;
  border-radius: 20px;
  background-color: #fff;
}

.story_name_container {
  width: 100%;
  display: flex;
}

.story_name_line {
  margin-top: 10%;
  margin-bottom: 3%;
  border-bottom: 1px solid grey;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_story_name {
  text-align: center;
  border: none;
  z-index: 1;
  position: absolute;
  margin-top: -5%;
  font-weight: bold;
  font-size: 1.2em;
  max-width: 60%;
}

.form_section {
  padding: 20px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story_content {
  border-radius: 10px;
  border: 1px solid #68B7B4;
  /* border-color: #68B7B4; */
  width: 100%;
  height: 40vh;
  padding: 10px;
  padding-bottom: 40px;
}

.story_content>textarea {
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.story_content>textarea:focus {
  outline: 0px none transparent;
}

.story_content::placeholder {
  color: #AEB98A;
}

.section_one_nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.section_two_nav {
  margin-top: 10vh;
  width: 100%;
  text-align: left;
}

.checkmark {
  transform: scale(1.5);
  margin-right: 10px;
}

.section_three_container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_three_container.result_image {
  width: 100%;
}

.warning_text {
  width: 80%;
  color: red;
  font-size: 0.8em;
  margin-bottom: 3px;
}

.content_word_counter {
  width: 100%;
  text-align: right;
  margin-top: -30px;
  padding-right: 20px;
  z-index: 1;
}

.pink_text {
  color: #f13d58;
}

.green_text {
  color: #68B7B4;
}

.section_three_map_button {
  width: 100%;
}

.section_three_notebook_button {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .story_name_line {
    margin-top: 40px;
  }

  .input_story_name {
    margin-top: -15px;
  }

  .form_section {
    margin-top: 2%;
  }

  .result_image {
    width: 20%;
  }

  .section_three_map_button {
    width: 50%;
  }

  .section_three_notebook_button {
    width: 50%;
  }

}