.homepage_container {
  background-repeat: no-repeat;
  background-image: url("./../assets/images/map_partial_long.png");
  background-size: cover;
}

.refresh_button {
  margin-top: 20px;
  color: #f13d57;
  background-color: rgb(252, 249, 245, 0.6);
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  width: 70%;
  text-align: center;
}

.refresh_button > img {
  margin-left: 5px;
}

.gomap_button_container {
  text-align: center;
  padding-top: 30vh;
  transform: translateY(-25%);
  margin-bottom: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gomap_button_container > p {
  text-align: left;
  margin: 10%;
  padding: 5%;
  background-color: #fff;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  opacity: 0.8;
  font-family: Roboto;
  font-size: 0.8em;
  border-radius: 10px;
  color: #444;
}

.gomap_button {
  width: 80%;
}

.white_layer {
  /* background: #FDE5DE; */
  width: 100%;
  height: 100%;
}

.category_page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10vh; */
}

.category_selection_container {
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header_button {
  color: #f13d57;
  font-family: Prompt;
  font-weight: bold;
  font-size: 1.3em;
  padding: 10px;
  text-align: center;
}

.category_filter_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fcf9f5;
  margin-top: 20px;
  margin-bottom: 3vh;
  padding: 10px 0 10px 0;
  align-items: center;
}

.category_filter_box {
  width: 100%;
  max-height: 17vh;
  overflow: scroll;
  margin-bottom: 10px;
}

.category_box {
  background-color: #f13d57;
  color: white;
  box-shadow: 0px 2px 3px grey;
  border-radius: 25px;
  padding: 5px;
  margin: 0 5px 0 5px;
  flex-grow: 1;
}

.category_selection_row {
  display: flex;
  justify-content: space-evenly;
}

.category_box_active {
  background-color: grey !important;
}

.you_may_interested_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.category_button {
  background-color: "#F13D57";
  width: 80%;
  height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select_category_container {
  width: 80%;
}

@media only screen and (min-width: 768px) {
  .gomap_button {
    width: 30%;
  }

  .homepage_container {
    background-image: url("./../assets/images/map_full.png");
    /* background-size: 100vw 100vh; */
    background-size: cover;
  }

  .select_category_container {
    width: 30%;
  }

  .category_button {
    width: 30%;
  }

  .you_may_interested_container {
    width: 30%;
    padding: 0;
  }

  .gomap_button_container > p {
    text-align: left;
    margin: 5% 30%;
    padding: 2%;
    background-color: #fff;
    opacity: 0.8;
    font-family: BaiJamjuree;
    font-size: 1em;
    border-radius: 10px;
    color: #444;
  }
}
