.usernav_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: lightslategray 2px 2px 4px;
  font-family: Prompt;
}

.usernav_item {
  margin: 0 10px 0 10px;
  flex-grow: 1;
  text-align: center;
  padding: 20px 0 20px 0;
  background-color: #76C3C0;
  margin: 0;
  border: .5px solid lightslategray;
  color: white;
}