.thumbnail_container {
  background-color: #fdfaf6;
  margin: 15px 0 15px 0;
  border-radius: 20px;
  width: 100%;
}

.thumbnail_title_container {
  padding: 10px 40px 5px 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #fe8f7c;
  color: #ffefd8;
}

.thumbnail_content_container {
  padding: 10px 30px 5px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.thumbnail_content_container > p {
  line-height: 1.2em;
  word-break: break-all;
}

.thumbnail_sticker_bar {
  display: flex;
  align-items: center;
}

.thumbnail_sticker_bar > p {
  margin: 0;
  color: #68b6b4;
}

.trigger_warning_text {
  color: #f13d57;
  font-size: 1.1em;
  font-family: Mitr;
  text-align: left;
}

.title_text {
  font-family: Mitr;
  font-size: 1.2em;
  text-align: left;
}

.content_text {
  color: #f13d57;
  font-family: BaiJamjuree;
  font-size: 0.9em;
  text-align: left;
}

.author_alias_text {
  color: #68b6b4;
  font-family: CrimsonPro;
  font-size: 1.1em;
}

.sticker_counter {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #68b6b4;
}

.sticker_counter img {
  width: 30px;
}

.react_router_link_overwrite {
  text-decoration: none;
  color: #f13d57;
}
