.fake_header {
  /* height must match the header height */
  width: 100%;
  min-height: 10vh;
}

.header {
  font-family: MontSerrat;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  min-height: 10vh;
}


.header--nav {
  padding: 1vh 1em .5em .3em;
  margin: 0;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

.header--nav_item {
  color: #F13D57;
  list-style-type: none;
  margin: 0 .3em 0 .3em;
}

.nav_link:hover {
  text-decoration: underline;
  text-decoration-color: #F13D57;
}

.header--nav_item_signup {
  border: 1px #F13D57 solid;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: -0.4em;
}

.header--nav_logo {
  width: 15vw;
  filter: drop-shadow(2px 2px 2px #555);
}

.header--nav_item_first {
  margin-left: auto;
}

.header--nav_item_title {
  position: relative;
  list-style-type: none;
  margin-left: .5em;
  font-size: 2em;
}

.header--nav_item--logo>p {
  margin: 0;
  padding: 0;
  line-height: 1em;
  font-size: .8em;
}

.nav_logo_container {
  display: flex;
  flex-grow: 1;
}

.nav_text_container {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  align-content: flex-end;
}

.user_nav_item_dropdown {
  margin-top: 5px;
  position: absolute;
  text-align: center;
  color: #000;
  font-family: Prompt;
  font-weight: bold;
}

.user_nav_item_dropdown_first {
  padding: 5px 10px;
  background-color: #FCF9F5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 2px solid #ffeed8;
  border-left: 2px solid #ffeed8;
  border-right: 2px solid #ffeed8;
  border-bottom: 1px solid #68b7b4;
}

.user_nav_item_dropdown_first:hover {
  background-color: #ffeed8;
}

.user_nav_item_dropdown_middle {
  padding: 5px 10px;
  background-color: #FCF9F5;
  border-top: 1px solid #68b7b4;
  border-left: 2px solid #ffeed8;
  border-right: 2px solid #ffeed8;
  border-bottom: 1px solid #68b7b4;
}

.user_nav_item_dropdown_middle:hover {
  background-color: #ffeed8;
}

.user_nav_item_dropdown_last {
  padding: 5px 10px;
  background-color: #FCF9F5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #ffeed8;
  border-left: 2px solid #ffeed8;
  border-right: 2px solid #ffeed8;
  border-top: 1px solid #68b7b4;
}

.user_nav_item_dropdown_last:hover {
  background-color: #ffeed8;
}

@media only screen and (min-width: 768px) {
  .header--nav_logo {
    width: 60px;
  }

  .header--nav {
    padding: 1vh 2em;
  }

  .header--nav_item {
    margin: 0 2em .5em 0;
  }

  .header--nav_item_signup {
    margin-bottom: .2em;
  }
}