.no_scroll {
  overflow: hidden;
}

.feed_container {
  /* background-image: url("./../assets/images/map_trans.PNG"); */
  background-size: auto;
}

.write_icon {
  position: fixed;
  width: 80px;
  right: 20px;
  bottom: 20px;
  filter: drop-shadow(2px 2px 2px #555);
}