.notebook_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-family: Prompt;
}

.notebook_container > img {
  width: 90%;
  filter: drop-shadow(0px 5px 5px grey);
  padding: 20px 20px;
}

.notebook_actions_container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.notebook_cover_panel {
  position: absolute;
  display: flex;
  overflow-x: scroll;
  background-color: rgb(255, 238, 216, 0.6);
  padding: 10px;
  transform: translateY(-110%);
  z-index: 3;
}

.notebook_cover_panel > img {
  width: 40%;
  height: auto;
  transition: 0.5s;
  margin: 0 10px;
}

.notebook_cover_panel > img:hover {
  transform: scale(1.04);
  transform-origin: bottom;
  transition: 0.5s;
}

.pattern_container {
  width: 100%;
  padding: 20px;
}

.pattern_container > img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 5px 5px grey);
  display: flex;
}

.pattern_overlay_content {
  padding: 10px;
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.pattern_overlay_content > p {
  white-space: pre-wrap;
  margin-bottom: 0;
  font-size: 0.8em;
}

.share_social_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 10px 20px;
}

.share_heading_text {
  border-radius: 20px;
  background: rgb(255, 238, 216, 0.5);
  color: #f13d57;
  padding: 5px 20px;
}

.hidden {
  visibility: hidden;
}

.stickers_container {
  display: flex;
  flex-wrap: wrap;
}

.stickers_container > div {
  width: 20%;
  margin-right: 20px;
  text-align: center;
}

.stickers_container img {
  width: 100%;
}

.notebook_prologue {
  margin: 5% 10% 0 10%;
  font-size: 0.8em;
  font-family: Mitr;
}

@media only screen and (min-width: 768px) {
  .notebook_container {
    padding: 0 20px 20px 0;
  }

  .notebook_page {
    padding-top: 5vh;
    width: 40%;
    margin: auto;
  }

  .pattern_overlay_content {
    /* Very fine-tuned */
    width: 23%;
  }

  .pattern_overlay_content > p {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .notebook_cover_panel {
    justify-content: center;
    overflow-x: hidden;
  }

  .notebook_cover_panel > img {
    width: 15%;
    transition: 0.5s;
    margin: 0 10px;
  }

  .notebook_actions_container {
    justify-content: center;
  }

  .notebook_actions_container > div {
    margin: 0 20px;
  }

  .notebook_prologue {
    margin: 5% 10% 0 10%;
    font-size: 1em;
    font-family: Mitr;
  }
}
