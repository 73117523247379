.storyfold_container {
  background-image: url("../../assets/images/map_trans.PNG");
  background-size: cover;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-family: Mitr;
}

.location_icon {
  width: 40%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -26%;
}

.thumbnails_container {
  margin-top: 50%;
  background-color: rgb(255, 239, 216, 0.6);
  flex-grow: 1;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  padding: 0 30px 0 30px;
}

.dropdown_filter {
  text-align: right;
  margin-top: 50px;
  font-size: large;
  font-weight: 400;
  color: #f13d57;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown_filter_gomap {
  font-weight: bold;
}

.text_center {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .location_icon {
    width: 15%;
    margin-top: -10%;
  }

  .thumbnails_container {
    margin-top: 20%;
    padding: 0 30vw;
  }
}