.login_form_container {
  padding: 50px 30px 30px 30px;
  font-family: Montserrat;
  background-color: #fff;
  flex-grow: 1;
}

.signup_form_container {
  padding: 50px 30px 30px 30px;
  font-family: Montserrat;
  background-color: #fff;
  flex-grow: 1;
  width: 100%;
}

.text_topic {
  font-family: BreakFill;
  color: #f13d57;
  font-size: 4em;
}

.signup_page {
  background-image: url("../assets/images/map_full.png");
  background-size: cover;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_page {
  background-image: url("../assets/images/map_full.png");
  background-size: cover;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .signup_form_container {
    width: 30%;
    margin: 50px;

    border-radius: 50px;
    border: 2px solid #68b6b4;
    box-shadow: #555 0px 3px 15px;
  }

  .login_form_container {
    width: 30%;
    margin: 50px;

    border-radius: 50px;
    border: 2px solid #68b6b4;
    box-shadow: #555 0px 3px 15px;
  }
}
