.user_posts_container {
  width: 100%;
  margin-top: 30px;
  font-family: Prompt;
}

.user_posts_container>h3 {
  text-align: center;
  color: #AEBA88;
}

.user_posts_container>table {
  width: 100%;
}

.table_row {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 0.7em;
}

.table_row_head>th {
  padding: 10px 10px;
  color: #AEBA88;
  font-size: 0.9em;
}

.table_row>td {
  padding: 10px 10px;
}

.post_dropdown_container {
  padding: 10px;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.post_dropdown_container>div {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 0;
}